import React from "react";

import { Helmet } from "react-helmet";

import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>GeoSolver</title>
        <meta property="og:title" content="GeoSolver" />
      </Helmet>
      <div
        data-role="Header"
        className="home-navbar-container home-navbar-container"
      >
        <div className="home-navbar">
          <div className="home-logo">
            <div class="flex-container">
              <a href="#home-page">
                <img
                  alt="image"
                  src="/geosolver_logo.png"
                  className="home-image"
                />
              </a>
            </div>
          </div>
          <div className="home-links-container">
            <a href="#features" className="home-link home-link Anchor">
              Features
            </a>
            <a href="#products" className="home-link1 home-link1 Anchor">
              Platforms
            </a>
            <a href="#contact" className="home-link3 home-link3 Anchor">
              Privacy policy
            </a>
          </div>
          <div className="home-cta-container">
            <div data-role="BurgerMenu" className="home-burger-menu">
              <svg viewBox="0 0 1024 1024" className="home-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
          <div data-role="MobileMenu" className="home-mobile-menu">
            <div className="home-top">
              <img alt="image" src="/logo.jpg" className="home-image1" />
              <div data-role="CloseMobileMenu" className="home-container1">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="home-mid">
              <div className="home-links-container1">
                <a href="#features" className="home-link home-link4 Anchor">
                  Who are we?
                </a>
                <a href="#products" className="home-link1 home-link5 Anchor">
                  Products
                </a>
                <a href="#contact" className="home-link3 home-link7 Anchor">
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-separator"></div>
      <div id="home-page" className="home-heading-container">
        <h2 className="home-text04 Section-Heading">GeoSolver</h2>
        <span className="home-text05 Section-Text">Snap, Solve, Learn</span>
      </div>
      <div id="features" className="home-features">
        <div className="home-cards-container">
          <div className="home-feature-card">
            <img alt="image" src="/icon1.png" className="home-image3" />
            <span className="home-heading1 Card-Heading">
              Instant Problem Recognition
            </span>
            <span className="home-text06 Card-Text">
              Snap a photo, and let AI detect and solve geometric problems
              instantly. Transform your device into a powerful geometry problem
              solver with state-of-the-art image recognition technology.
            </span>
          </div>
          <div className="home-feature-card1">
            <img alt="image" src="/icon2.png" className="home-image4" />
            <span className="home-heading2 Card-Heading">
              Interactive Learning
            </span>
            <span className="home-text07 Card-Text">
              Step-by-step solutions to not just get the answer, but understand
              the process. Engage with interactive tutorials that guide you
              through complex geometry problems, enhancing your learning and
              understanding.
            </span>
          </div>
          <div className="home-feature-card2">
            <img alt="image" src="/icon3.png" className="home-image5" />
            <span className="home-heading3 Card-Heading">
              Personalized Practice
            </span>
            <span className="home-text08 Card-Text">
              AI-driven personalized problem sets to strengthen your geometry
              skills. Adapt your learning journey with tailored exercises that
              fit your progress and challenge level, ensuring effective practice
              and mastery.
            </span>
          </div>
        </div>
      </div>
      <div id="products" className="home-products">
        <div className="home-section-separator1"></div>
        <div h2 className="home-text-products Section-Heading">
          Platforms
        </div>
        <div id="products" className="home-products">
          <div className="home-product-card">
            <div className="home-card-content">
              <h3 className="home-text09 BigCard-Heading">Android</h3>
              <span className="home-text10 Card-Text">
                <img
                  alt="image"
                  src="/google-play-badge.png"
                  className="home-image"
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              Arriving: July 2024
            </div>
          </div>
          <div className="home-product-card1">
            <div className="home-card-content1">
              <h3 className="home-text11 BigCard-Heading">iOS</h3>
              <span className="home-text12 Card-Text">
                <img alt="image" src="/ios_badge.svg" className="home-image" />
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              Arriving: July 2024
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="home-contact home-contact">
        <div className="home-content-container">
          <h2 className="home-text24 Section-Heading">Privacy policy:</h2>
          <div className="home-locations-container">
            <div className="home-location-1">
              <div className="home-adress">
                <span className="Section-Text">
                  You can download our privacy policy here:
                </span>
                <a
                  href="/PrivacyPolicy(ENG).pdf"
                  target="_blank"
                  className="policy-link"
                >
                  Link
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
